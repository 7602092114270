@import url("./lib/fonts/fonts.css");
@import url("./lib/icons.less");
@import url("./lib/settings.less");


html{
	min-height: 100%;
	width:100%;
	height:100%;
	margin:0;
	padding:0;
} 

body{
	min-height: 100%;
	width:100%;
	height:auto;
	display:block;
	
	background-color:@c_6th;
	
	background-repeat: no-repeat;
	background-position: top center;
	background-attachment: fixed;
	background-size: cover;

	position: relative;
	top:0;
	z-index: 0;

	padding: 0;
	margin: 0;

	min-width: 100%;

	font-size: @f_mainSize;
	
	& > canvas{
		width: 100%;
 		height: 100%;
 		position: fixed;
 		z-index: 1;
 		top: 50%;
        left: 50%;
         
        
	}
}

#main-index-section{

	display:block;
	margin-top:0;
	position:absolute;
	top:@p_navTop;

}

